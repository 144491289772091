.radio-btn {
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #707070;
    border-radius: 50%;
    outline: none;
  }

  input[type="radio"]:checked {
    background-color: #707070;
    outline: none;
  }
}
