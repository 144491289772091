@import "../variable.scss";

.page {
  display: inline;
  &-header {
    padding: 1rem 3rem;
    border-bottom: 1px solid #dedede;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);

    h6 {
      font-family: Avenir-Next-Cyr-Medium, Helvetica, sans-serif;
      // font-size: 1.375rem;
      margin-bottom: 0px;
    }
  }
  &-inner {
    position: relative;
    height: calc(100vh - 60px);
    overflow-y: auto;

    &-section {
      overflow-x: hidden;
      padding: 1.3rem 3rem 1rem;
    }
  }
  &-content-inner {
    width: 100%;
    min-width: calc(100% - 270px);
  }
}

select {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  color: $light-gray;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid $cerise;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  outline: none;
}

.selects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .select {
    flex-basis: 23%;

    label {
      font-family: Avenir-Next-Cyr-Medium, Helvetica, sans-serif;
    }
  }
}

.exam {
  &-content {
    height: unquote("-webkit-calc(100vh - 170px)");
    transition: height 500ms linear;

    &-left {
      height: 100%;
      overflow-y: hidden;
    }
  }

  &-textarea {
    resize: none;
    width: 100%;
    height: 95%;
    border: 1px solid #ec2a7b;
    border-radius: 5px;

    &:focus {
      outline: none !important;
      border: 2px solid #ec2a7b;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #969da5;
  border-radius: 25px;
}

@-moz-document url-prefix() {
  .exam-content {
    height: 74vh;
  }
  .exam-content:-moz-full-screen {
    height: 78vh;
  }
}

.form-check-input {
  margin-top: 0.2rem;
  &::before {
    margin-right: 10px;
    content: "";
    display: inline-block;
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #dae2e7;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  &::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    font-size: 0.7em;
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    color: #fff;
  }

  &:checked::before {
    background-color: #ec2a7b;
  }

  &:checked::after {
    opacity: 1;
  }
}
