$secondary: #a9508a;
$white: #ffffff;
$cerise: #ec2a7b;
$light-plum: #a8518a;
$dark-pink: #c84084;
$charcoal-grey: #3a3c42;
$cool-grey: #969da5;
$cerise-two: #ec2d7c;
$warm-grey: #9c9c9c;
$strong-pink: #ed047b;
$cerise-three: #ed0d7b;
$cerise-four: #ed187c;
$pinkish-grey: #d1d1d1;
$light-gray: #495057;

.bg-warm-grey {
  background-color: $warm-grey;
}

.text-light-gray {
  color: $light-gray;
}

.term-color {
  color: #007bff;
}