/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "./bootstrap/bootstrap.scss";
@import "./variable.scss";
@import "./fonts/fonts.scss";
@import "./layouts/layouts.scss";
@import "./utilites/utilites.scss";
@import "~quill/dist/quill.bubble.css";
@import "./components/index.scss";

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Avenir-Next-Regular, Arial, Helvetica, sans-serif;
}
ul {
  margin: 0;
  padding: 0;
}

.btn-primary:hover,
.btn-dark-pink:hover {
  color: #d01367;
  background-color: #fff;
  border-color: #d01367;
}

.mat-icon {
  font-size: 1.75rem;
  line-height: 25px;
  color: $cerise;
}
/* Background Color*/
.bg-img-primary {
  background-image: linear-gradient(to left, $light-plum, $cerise);
}

.bg-footer {
  background-color: $charcoal-grey;
}
.m-bg-primary {
  background-color: $dark-pink;
}
.bg-dashboard {
  background-color: #f5f5f5;
}
/* Color*/
.m-primary {
  color: $dark-pink;
}

.m-secondary {
  color: $cerise;
}

.m-cool-grey {
  color: $cool-grey;
}

.bg-cool-grey {
  background-color: $cool-grey;
}

.m-light-plum {
  color: $light-plum;
}

.m-white {
  color: $white;
}

// Page spinner start

#page-spinner {
  position: fixed;
  background: #e9edf3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 2px;
  opacity: 0.8;
}
.page-spinner-loading {
  width: 120px;
  height: 120px;
  border: 4px solid #fff;
  border-radius: 50%;
  border-left: 4px solid blue;
  border-right: 4px solid blue;
  animation: load 1s linear infinite;
  position: absolute;

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

// Page spinner end
.m-btn-primary {
  text-decoration: none;
  color: $white;
  padding: 0.25rem 1rem;
  border: 1px solid $dark-pink;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.39);
  font-family: Avenir;

  &:hover {
    background-color: $white;
    color: $dark-pink;
    text-decoration: none;
    box-shadow: none;
  }
}

.third-section {
  & img {
    object-fit: contain;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    border: 1px solid;
    padding: 5px 10px;
    width: 120px;
    text-align: center;
  }
}

.m-btn {
  border: 1px solid white;
}

.m-submit {
  color: $white !important;
  background: linear-gradient(-45deg, #a9508a, #ec2a7b);
}

.submit {
  letter-spacing: 4px;
  font-weight: 300;
}
.m-disabled {
  color: #818892;
  background: transparent;
  pointer-events: none;
}

.social-icon {
  color: white;
  border: 1px solid;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 1.3rem;
}

.m-btn {
  background-color: #fff;
  color: #a8518a;
  font-weight: 600;
}

.m-btn-outline-danger {
  color: $strong-pink;
  border-color: $strong-pink;

  &:hover {
    color: #fff;
    background-color: $strong-pink;
  }
}

.pointer {
  cursor: pointer;
}

.no-drop {
  cursor: no-drop;
}

.disabled:hover {
  color: #fff;
  background-color: #ec2a7b;
  border-color: #ec2a7b;
}

::ng-deep.mat-tab-group {
  font-family: Avenir-Next-Regular, Helvetica, sans-serif !important;
}

.ql-container {
  font-family: "Avenir-Next-Cyr-Regular", Helvetica, Arial, sans-serif;
}

.ql-editor {
  padding: 2px 15px;
}

.question-group {
  .question-numbers {
    h3 {
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }

    p {
      // display: flex;
      font-size: 16px;
      p {
        margin-right: 5px;
      }
    }
  }
  .question-image {
    height: 310px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

:host ::ng-deep .mat-dialog-container {
  height: inherit !important;
}
.mat-dialog-container, .mat-expansion-panel-header, .mat-expansion-panel-header.mat-expanded {
  height: inherit !important;
}
.mat-expansion-panel-header, .mat-expansion-panel-header.mat-expanded {
  padding: 5px 24px !important;
}
p.matchingParagraph{
  display: inline-block;
  padding: 2px;
  background: silver;
}

.dummyClassForInline p {
  display: inline !important;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}