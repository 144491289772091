@import "../../../variable.scss";

.admin-add-test-content {
  background-color: rgba($color: #a8518a, $alpha: 0.1);
  overflow: hidden;

  &-body {
    height: calc(100vh - 200px);
    overflow: auto;
  }
}

.choose-queation-type {
  transition: 1s linear;
  &-head {
    border: 1px solid $cool-grey;
    border-radius: 5px 5px 0 0;
  }
  &-body {
    &-left {
      border-right: 1px solid $cool-grey;
    }

    &-left,
    &-right {
      width: 50%;
    }
  }
}

.admin-add-test-content,
.choose-queation-type-body {
  border-radius: 0 0 5px 5px;
}
