.remeaning {
  animation-name: remeaning;
  animation-duration: 1s;
  animation-iteration-count: 5;
  opacity: 1;
}

@keyframes remeaning {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
