@import "./addTest/layout";

.main .editor {
  width: 635px;
}

.editor {
  border: 1px solid #707070;
  border-radius: 5px;
}

.border-color {
  border-color: #707070;
}

.question-type {
  &-container {
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 5px;
  }

  &-number {
    border: 1px solid;
    min-width: 25px;
    border-radius: 5px;
    text-align: center;
    background-color: #e5e0e0;
    margin-right: 16px;
  }

  &-question {
    .editor {
      width: 560px;
    }
  }

  &-answer {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 0 5px;
    width: 100%;

    input,
    input:focus {
      border: none;
      outline: none;
      width: 100%;
    }

    &-true {
      padding-top: 4px;

      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 15px;
        height: 15px;
        padding: 2px;
        /* background-color only for content */
        background-clip: content-box;
        border: 2px solid #707070;
        border-radius: 50%;
        outline: none;
      }

      input[type="radio"]:checked {
        background-color: #707070;
        outline: none;
      }
    }
  }
}

.bg-answer {
  background-color: #e5e0e0;

  input {
    background: transparent;
  }
}
