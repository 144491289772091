@media only screen and (max-width: 576px) {
  .exam-textarea {
    height: 300px !important;
  }

  .text-secondary.low {
    margin-top: 21rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bg-primary.low {
    margin-top: 30rem !important;
  }

  .bandDescription {
    &-header,
    &-content {
      display: none !important;
    }
  }
}


