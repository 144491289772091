.f-12 {
  font-size: 12px;
  font-family: Avenir;
}

.f-14 {
  font-size: 14px;
  font-family: Avenir-Next-Cyr-Regular;
}


.f-16 {
    font-size: 16px;
    font-family: Avenir-Next-Cyr-Regular;
  }
