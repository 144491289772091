.payment {
    width: 100%;
    height: 100vh;
    .box {
      position: relative;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
    }
  }
  