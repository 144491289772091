@font-face {
  font-family: Avenir-Next-Regular;
  src: url("./avenir-next-regular.ttf");
}

@font-face {
  font-family: Avenir-Roman;
  src: url("./Avenir-Roman.ttf");
}

@font-face {
  font-family: Avenir-Next-Cyr-Regular;
  src: url("./AvenirNextCyr-Regular.ttf");
}

@font-face {
  font-family: Avenir-Next-Cyr-Bold;
  src: url("./AvenirNextCyr-Bold.ttf");
}

@font-face {
  font-family: Avenir-Next-Cyr-Medium;
  src: url("./AvenirNextCyr-Medium.ttf");
}

.f-1 {
  font-size: 1rem;
}

.f-2 {
  font-size: 1.5rem;
}

.f-3 {
  font-size: 2rem;
}

.f-4 {
  font-size: 2.5rem;
}

.f-5 {
  font-size: 3rem;
}

.font-weight-500 {
  font-weight: 500;
}
